import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MolecularStructure from '../../../components/AdmetProperties/MolecularStructure';
import CompareMoleculeView from '../../../components/AdmetProperties/CompareMoleculeView';
import ParameterGraphicalViewContanier from '../../../components/AdmetProperties/ParameterGraphicalView';

function OverviewValuesContainer({
  admetData, isOverview, comparatorSmileData, setComparatorSmileData, radarParameters, setRadarParameters,
}) {
  const [isCompareView, setIsCompareView] = useState(false);

  useEffect(() => {
    const smile = admetData?.smiles;
    if (!(smile in radarParameters)) {
      // setDefaultLabels(['Log P', 'CYP3A4 Inhibition', 'HIA', 'BBB', 'hERG Block']);
      // setDefaultValues([admetData?.logP, admetData?.CYP3A4_Veith, admetData?.HIA_Hou, admetData?.BBB_Martins, admetData?.hERG]);
      setRadarParameters((prev) => ({
        ...prev,
        [admetData.smiles]: {
          labels: ['Log P', 'CYP3A4 Inhibition', 'HIA', 'BBB', 'hERG Block'],
          values: [admetData?.logP, admetData?.CYP3A4_Veith, admetData?.HIA_Hou, admetData?.BBB_Martins, admetData?.hERG],
        },
      }));
    }
  }, []);

  useEffect(() => {
    console.log('isCompareView', isCompareView);
  }, [isCompareView]);

  const renderView = () => {
    if (isCompareView) {
      return <CompareMoleculeView admetData={admetData} setIsCompareView={setIsCompareView} setComparatorSmileData={setComparatorSmileData} />;
    }
    return <ParameterGraphicalViewContanier setIsCompareView={setIsCompareView} admetData={admetData} comparatorData={comparatorSmileData[admetData.smiles]} radarParameters={radarParameters} setRadarParameters={setRadarParameters} isOverview={isOverview} />;
  };

  return (
    <div className="overview-body">
      <div className="combinations-output-item">
        <div className="combinations-output-item-card">
          <MolecularStructure admetData={admetData} />
        </div>
      </div>
      <div className="combinations-output-item">
        <div className="combinations-output-item-card">{renderView()}</div>
      </div>
    </div>
  );
}

OverviewValuesContainer.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  isOverview: PropTypes.bool.isRequired,
  comparatorSmileData: PropTypes.string.isRequired,
  setComparatorSmileData: PropTypes.func.isRequired,
  radarParameters: PropTypes.arrayOf.isRequired,
  setRadarParameters: PropTypes.func.isRequired,
};

export default OverviewValuesContainer;
