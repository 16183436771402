import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap, mergeMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall, { apiCallwithoutCT } from '../../../../utils';

// generate indications
const GENERATE_HCTP_COMBINATIONS = 'GENERATE_HCTP_COMBINATIONS';
const GENERATE_HCTP_COMBINATIONS_SUCCESS = 'GENERATE_HCTP_COMBINATIONS_SUCCESS';
const GENERATE_HCTP_COMBINATIONS_FAILURE = 'GENERATE_HCTP_COMBINATIONS_FAILURE';
const GENERATE_HCTP_COMBINATIONS_RESET = 'GENERATE_HCTP_COMBINATIONS_RESET';

export const generateHCTPCombinationsAction = createAction(GENERATE_HCTP_COMBINATIONS);
export const generateHCTPCombinationsResetAction = createAction(GENERATE_HCTP_COMBINATIONS_RESET);

const generateHCTPCombinationsSuccess = createAction(GENERATE_HCTP_COMBINATIONS_SUCCESS);

const generateHCTPCombinationsFailure = createAction(GENERATE_HCTP_COMBINATIONS_FAILURE);
export const generateHCTPCombinationsEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_HCTP_COMBINATIONS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/generateHCTPCombinations?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateHCTPCombinationsSuccess(res)),
    catchError((err) => of(generateHCTPCombinationsFailure(err))),
  )),
);

export const generateHCTPCombinationsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateHCTPCombinationsAction, (state) => {
        state.loading = true;
      })
      .addCase(generateHCTPCombinationsSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateHCTPCombinationsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(generateHCTPCombinationsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// upload file
const HCTP_PAIRS_UPLOAD_FILE_INPUT = 'HCTP_PAIRS_UPLOAD_FILE_INPUT';
const HCTP_PAIRS_UPLOAD_FILE_INPUT_SUCCESS = 'HCTP_PAIRS_UPLOAD_FILE_INPUT_SUCCESS';
const HCTP_PAIRS_UPLOAD_FILE_INPUT_FAILURE = 'HCTP_PAIRS_UPLOAD_FILE_INPUT_FAILURE';
const HCTP_PAIRS_UPLOAD_FILE_INPUT_RESET = 'HCTP_PAIRS_UPLOAD_FILE_INPUT_RESET';

export const hctpPairsFileInputAction = createAction(HCTP_PAIRS_UPLOAD_FILE_INPUT);
export const hctpPairsFileInputResetAction = createAction(HCTP_PAIRS_UPLOAD_FILE_INPUT_RESET);

const hctpPairsFileInputSuccess = createAction(HCTP_PAIRS_UPLOAD_FILE_INPUT_SUCCESS);
const hctpPairsFileInputFailure = createAction(HCTP_PAIRS_UPLOAD_FILE_INPUT_FAILURE);

export const hctpPairsFileInputEpic = (actions$) => actions$.pipe(
  ofType(HCTP_PAIRS_UPLOAD_FILE_INPUT),
  mergeMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_ctp}/uploadDocument?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => hctpPairsFileInputSuccess(res)),
    catchError((err) => of(hctpPairsFileInputFailure(err))),
  )),
);
export const hctpPairsFileInputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(hctpPairsFileInputAction, (state) => {
        state.loading = true;
      })
      .addCase(hctpPairsFileInputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(hctpPairsFileInputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(hctpPairsFileInputResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
