import React from 'react';
import PropTypes from 'prop-types';
import tooltipData from '../data';

function DistributionandExcretionComponent({ admetData, createItem }) {
  return (
    <div className="container-layout container-layout-combine-view ">
      <div className="container-layout-card">
        <div className="container-layout-card-title">Distribution</div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Properties', isHeader: true })}
          {createItem({ text: 'Values', isHeader: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'PPBR', tooltip: tooltipData.PPBR })}
          {createItem({ text: admetData?.PPBR_AZ_y, isValue: true, unit: '%' })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'VDss', tooltip: tooltipData.Vdss })}
          {createItem({ text: admetData?.VDss_Lombardo_y, isValue: true, unit: ' L/kg' })}
        </div>
        <div className="properties-items container-layout-card-item top-line">
          {createItem({ text: 'Properties', isHeader: true })}
          {createItem({ text: 'Probability', isHeader: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'BBB', tooltip: tooltipData.BBB })}
          {createItem({ isGraph: true, value: admetData?.BBB_Martins })}
        </div>
      </div>
      <div className="container-layout-card">
        <div className="container-layout-card-title">Excretion</div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Properties', isHeader: true })}
          {createItem({ text: 'Values', isHeader: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Half Life', tooltip: tooltipData['Half Life'] })}
          {createItem({ text: admetData?.Half_Life_Obach_y, isValue: true, unit: 'hr' })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Drug Clearance', tooltip: tooltipData['Drug Clearance'] })}
          {createItem({ text: admetData?.Clearance, isValue: true, unit: 'mL.min-1.g-1' })}
        </div>
      </div>
    </div>
  );
}
DistributionandExcretionComponent.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  createItem: PropTypes.func.isRequired,
};
export default DistributionandExcretionComponent;
