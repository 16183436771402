import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useAppSelector from '../../../../../utils/redux-hook';
import { generateSARLeadAction } from '../../../pages/SARInputPage/logic';
import {
  checkAdmetSARStatusAction, checkAdmetSARStatusResetAction, getAdmetCompareOutputAction, isReadAdmetSAROutputAction,
} from './logic';
import Loader from '../../../../../common/components/Loader';

function CompareMoleculeView({
  admetData, setIsCompareView, setComparatorSmileData,
}) {
  const dispatch = useDispatch();
  const [smile, setSmile] = useState('');
  const checkAdmetStatusResponse = useAppSelector((state) => state.checkAdmetStatusResponse);
  const getAdmetCompareOutputResponse = useAppSelector((state) => state.getAdmetCompareOutputResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onCompareClick = () => {
    if (smile !== '') {
      setIsSubmitted(true);
      setIsLoading(true);
      const request = {
        params: queryString.stringify({
          service: 'ADMET_PROPERTIES',
        }),
        body: [smile],
      };
      dispatch(generateSARLeadAction(request));
      // dispatch(checkAdmetSARStatusAction());
      // setIsSuccess(true);
    }
  };

  useEffect(() => {
    if (checkAdmetStatusResponse.flag && checkAdmetStatusResponse.data.length > 0) {
      if (checkAdmetStatusResponse.data[0]?.status === 'PENDING') {
        if (checkAdmetStatusResponse.data[0]?.is_read === false) {
          setIsLoading(true);
        }
      } else if (checkAdmetStatusResponse.data[0]?.status === 'COMPLETED' && isSubmitted) {
        if (checkAdmetStatusResponse.data[0]?.is_read !== true) {
          const request2 = {
            job_id: checkAdmetStatusResponse.data[0]?.job_id,
          };
          dispatch(getAdmetCompareOutputAction(queryString.stringify(request2)));
          dispatch(checkAdmetSARStatusResetAction({}));
          setIsLoading(true);
          const request1 = {
            job_id: checkAdmetStatusResponse.data[0]?.job_id,
            is_read: true,
          };
          dispatch(isReadAdmetSAROutputAction(queryString.stringify(request1)));
          // setIsCompareView(false);
        }
      } else if (checkAdmetStatusResponse.data[0]?.status === 'REJECTED') {
        setIsLoading(false);
        setIsCompareView(false);
        dispatch(checkAdmetSARStatusResetAction({}));
      }
    }
  }, [JSON.stringify(checkAdmetStatusResponse)]);
  useEffect(() => {
    let interval = '';
    if (isSubmitted) {
      interval = setInterval(() => {
        dispatch(checkAdmetSARStatusAction());
      }, 2000);
    }
    return () => {
      if (interval !== '') {
        clearInterval(interval);
        // setIsCompareView(false);
      }
    };
  }, [isSubmitted, getAdmetCompareOutputResponse]);
  useEffect(() => {
    if (getAdmetCompareOutputResponse.flag && getAdmetCompareOutputResponse.data && isSubmitted) {
      setComparatorSmileData((prev) => {
        prev[admetData.smiles] = { ...getAdmetCompareOutputResponse.data[0] };
        return {
          ...prev,
        };
      });
      setIsCompareView(false);
      setIsLoading(false);
    }
  }, [getAdmetCompareOutputResponse]);
  return (
    <Loader loading={isLoading}>
      <div className="compare-molecule-view-main">
        <div className="compare-molecule-view-headings">
          <div className="compare-molecule-view-title container-layout-card-title">Compare Molecule</div>
          <div
            className="compare-molecule-view-close-btn"
            onClick={() => {
              setIsCompareView(false);
            }}
            aria-hidden
          />
        </div>
        <div className="compare-molecule-view-sub-headings">
          <div className="compare-molecule-view-sub-title">Please provide the molecule you wish to compare</div>
          <div className="compare-molecule-view-try-example">Try Example</div>
        </div>
        <div className="molecule-input-view">
          <TextArea
            value={smile.split('\n')[0]}
            rows={7}
            placeholder="Enter smile string here"
            onChange={(e) => {
              setSmile(e.target.value);
            }}
            disabled={false}
          />
        </div>
        <div className="compare-molecule-view-footer">
          <div className="compare-btn" style={{ cursor: 'pointer' }} onClick={() => onCompareClick()} role="button" disabled aria-hidden>
            Compare
          </div>
        </div>
      </div>
    </Loader>
  );
}

CompareMoleculeView.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  setIsCompareView: PropTypes.func.isRequired,
  setComparatorSmileData: PropTypes.func.isRequired,
};
export default CompareMoleculeView;
