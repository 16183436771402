import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { terminalsPath } from '../../../../app/components/Card/data';
import { generateHCTPCombinationsAction, hctpPairsFileInputAction } from './logic';
// import { terminalsPath } from '../../../../app/components/Card/data';

const { TextArea } = Input;

function SaveProjectModal({
  open, setOpen, requestBody,
}) {
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const processInput = (text) => text?.split('\n').filter((line) => line);

  const onSaveAndGenerate = () => {
    const updatedDrugs = [];
    const updatedIndications = [];
    console.log('Request body --->', requestBody);

    processInput(requestBody.drugIndication)?.forEach((item) => {
      const [drug1, indication1] = item.split('~');
      updatedDrugs.push(drug1?.trim());
      updatedIndications.push(indication1?.trim());
    });
    const request = {
      params: queryString.stringify({
        service: 'HCTP',
        job_name: projectName,
        description,
      }),
      body: {
        drugs: updatedDrugs,
        indications: updatedIndications,
      },
    };
    const request1 = {
      params: queryString.stringify({
        service: 'HCTP',
        job_name: projectName,
        description: requestBody.description ? requestBody.description : undefined,
      }),
      body: requestBody.pairsFile,
    };
    dispatch(requestBody.isUploadFile ? hctpPairsFileInputAction(request1) : generateHCTPCombinationsAction(request));
    navigate(`${terminalsPath.metad3_ctp_workspace}`);
    toast(`Project “${projectName}” is added successfully in My workspace`, {
      position: 'top-right',
      autoClose: 2000,
      pauseOnHover: false,
      draggable: true,
      newestOnTop: true,
      type: 'default',
      hideProgressBar: false,
      closeButton: true,
    });
    setOpen(false);
  };
  return (
    <Modal footer={null} closeIcon={null} closable onCancel={() => setOpen(false)} open={open} className="save-project-modal">
      <div className="save-project-title">Save Project</div>
      <div className="save-project-content">
        <div className="save-project-name">
          <div className="save-project-name-title">Save new project as per new input changes</div>
          <div className="save-project-name-input">
            <Input placeholder="Enter name" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
          </div>
        </div>
        <div className="save-project-description">
          <div className="save-project-description-title">
            Description
            <span className="description-optional">(Optional)</span>
          </div>
          <div className="save-project-description-input">
            <TextArea placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>
        <div className="btns-row">
          <div className="cancel-btn" onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} role="presentation">Cancel</div>
          <Button disabled={!projectName} onClick={() => onSaveAndGenerate()} className="save-generate-btn">
            Save & Generate output
          </Button>
        </div>
      </div>
      <ToastContainer />
    </Modal>
  );
}

SaveProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  requestBody: PropTypes.objectOf.isRequired,
};

export default SaveProjectModal;
