/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, Input } from 'antd';
import axios from 'axios';
import Rcookie from 'react-cookies';
import SvgLoader from '../../../../common/components/SvgLoader';

function InputBox({
  inputHandler, isDisabled, inputStyle, smiles, setFormData, setInputStyle,
}) {
  const { TextArea } = Input;
  const [inputValue, setInputValue] = useState(smiles !== null ? smiles : '');
  const [dataset, setDataset] = useState([-1, 0, 0]);
  const inputStyles = ['Textual Input', 'Draw Molecular Structure/s', 'Upload a Document'];
  const [isFileSelected, setIsFileSelected] = useState(false);

  const onInputFileChange = (fileList) => {
    if (fileList.length > 1) {
      fileList.shift();
    }
    const files = fileList;
    if (files.length) {
      const formData = new FormData();
      formData.append('file', files[0].originFileObj);
      setIsFileSelected(true);
      setFormData(formData);
    }
    // change below after api integration
  };

  const handleDatasetChange = (arr) => {
    setDataset(arr);
    let value = {};
    if (arr[0] === 1) {
      setInputStyle(true);
      setIsFileSelected(false);
      setFormData('');
      value = { btn: inputStyles[0] };
    } else {
      setInputStyle(false);
      value = { btn: inputStyles[2] };
    }
    inputHandler(value);
  };

  const props = {
    name: 'file',
    accept: '.xls,.xlsx',
    multiple: true,
    progress: true,
    beforeUpload: () => false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        onInputFileChange(info.fileList);
      }
    },
    onRemove() {
      setIsFileSelected(false);
      setFormData('');
    },
  };

  useEffect(() => {
    const splits = inputValue.split('\n');
    // if (splits.length <= 5) {
    const inputs = {
      smile: splits,
    };
    inputHandler(inputs);
    // }
  }, [inputValue]);

  useEffect(() => {
    inputStyles.forEach((item, index) => {
      if (item === inputStyle) {
        setDataset((prev) => {
          const newDataset = prev;
          newDataset[index] = 1;
          return newDataset;
        });
        inputHandler({ dataset: inputStyles[index] });
      }
    });
  }, []);

  const handleInputChange = (e) => {
    if (e !== undefined) {
      // if ((e.match(/\n/g) || []).length < 5) {
      setInputValue(e);
      // }
    }
  };

  const downloadTemplateFile = async () => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
      'Content-Type': 'blob',
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    };
    const apiUrl = `${process.env.apiUrl_sar}/templateDownload`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      const outputFilename = 'sar_input_template.xlsx';

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <div className="provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Select Input Style</div>
        <div className="input-fields">
          <div className="select-dataset">
            <div className="select-dataset-footer">
              <div className="select-dataset-footer-btn">
                <button type="button" className={`select-button ${dataset[0] === 1 ? 'active' : ''}`} onClick={() => handleDatasetChange([1, 0, 0])} disabled={isDisabled}>
                  Textual Input
                </button>
              </div>
              <div className="select-dataset-footer-btn">
                <button type="button" className={`select-button ${dataset[1] === 1 ? 'active' : ''}`} onClick={() => handleDatasetChange([0, 1, 0])} disabled>
                  Draw Molecular Structure/s
                </button>
              </div>
              <div className="select-dataset-footer-btn">
                <button type="button" className={`select-button ${dataset[2] === 1 ? 'active' : ''}`} onClick={() => handleDatasetChange([0, 0, 1])} disabled={isDisabled}>
                  Upload a Document
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="provide-input-sec">
          <div className="pro-input-title">

            <div className="input-container-title">Provide Input</div>
            <div className="input-fields-value try-example-section">
              <text
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => {
                  // setIndication('Lung Cancer');
                  if (!isDisabled) {
                    setInputValue('CC1=C(C(C(=C(N1)C)C(=O)OCCN2CCN(CC2)C(C3=CC=CC=C3)C4=CC=CC=C4)C5=CC(=CC=C5)[N+](=O)[O-])C(=O)OC');
                  }
                  // setDefaultIndication('Lung Cancer');
                }}
              >
                Try Example
              </text>
            </div>
          </div>
          <div className="input-fields">
            <div className="input-fields-value">
              {(dataset[0] === 1 || dataset[0] === -1) && (
                <TextArea
                  rows={8}
                  value={inputValue}
                  placeholder="Enter smile/s string here"
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                  disabled={isDisabled}
                />
              )}
              {dataset[2] === 1 && (
                <div className="upload-file-main">
                  <div className="upload-document-title">
                    # To upload the document, first update your data in the provided template file, and then upload it.
                    <span className="download-template-text" style={{ cursor: 'pointer' }} onClick={() => downloadTemplateFile()} aria-hidden>
                      Download the template file.
                    </span>
                  </div>
                  <div className={`input-fields-upload${isFileSelected ? ' upload-file-item' : ''}`}>
                    <Upload {...props}>
                      {!isFileSelected && (
                        <p className="ant-upload-drag-icon">
                          <SvgLoader width={216} height={72} svgName="upload-file" />
                        </p>
                      )}
                      {!isFileSelected && (
                        <p className="ant-upload-text">
                          <span className="ant-upload-text-grey">Drag & drop molecule/s file or</span>
                          <span className="ant-upload-text-blue">Browse</span>
                        </p>
                      )}
                      {!isFileSelected && <p className="ant-upload-hint">The file should be in .xlsx format, and the maximum size limit is 5MB.</p>}
                    </Upload>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBox.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  inputStyle: PropTypes.string.isRequired,
  smiles: PropTypes.string.isRequired,
  setFormData: PropTypes.func.isRequired,
  setInputStyle: PropTypes.func.isRequired,
};

export default InputBox;
