import {
  Button, Divider, Input, Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import Header from '../../../../common/components/Header';
import { ctpUpdateWorkspaceStatusAction, ctpWorkspaceStatusAction, ctpWorkspaceStatusResetAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import { getCookie } from '../../../../utils';
import { terminalsPath } from '../../../../app/components/Card/data';
import { generateHCTPCombinationsResetAction } from '../../container/SaveProjectModal/logic';

function MyWorkspacePageCTP() {
  const dispatch = useDispatch();
  const ctpWorkspaceStatusResponse = useAppSelector((state) => state.ctpWorkspaceStatusResponse);
  // const [outputData, setOutputData] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const [currentJob, setCurrentJob] = useState({});
  const userEmailId = getCookie('usersEmailId');
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentService, setCurrentService] = useState([0, 0, 1]);
  const [serviceJobsList, setServiceJobsList] = useState({
    pos: [],
    cfs: [],
    hctp: [],
  });

  useEffect(() => {
    const request = {
      filters: JSON.stringify({ module_id: 'metad3_ctp', 'user_input.service': 'HCTP' }),
      from: 1,
      size: 100,
      searchQuery: searchQuery !== '' ? searchQuery : undefined,
      sort_order: sortOrder,
    };
    dispatch(ctpWorkspaceStatusAction(queryString.stringify(request)));
    setIsLoading(false);
    const intervalId = setInterval(() => {
      if (ctpWorkspaceStatusResponse.data) {
        const request1 = {
          filters: JSON.stringify({ module_id: 'metad3_ctp', 'user_input.service': 'HCTP' }),
          searchQuery: searchQuery !== '' ? searchQuery : undefined,
          sort_order: sortOrder,
          from: 1,
          size: 100,
        };
        dispatch(ctpWorkspaceStatusAction(queryString.stringify(request1)));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (ctpWorkspaceStatusResponse.flag) {
      // setOutputData(ctpWorkspaceStatusResponse.data);
      if (!currentJob.status) {
        setCurrentJob(ctpWorkspaceStatusResponse.data[0]);
      }
      setServiceJobsList((prev) => ({
        ...prev,
        hctp: ctpWorkspaceStatusResponse.data,
      }));
    }
  }, [ctpWorkspaceStatusResponse]);

  const onChangedJob = (job) => {
    setCurrentJob(job);
  };

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onEditName = (job) => {
    const request = {
      job_id: job.job_id,
      job_name: editing.editingName,
    };
    dispatch(ctpUpdateWorkspaceStatusAction(queryString.stringify(request)));
    const request1 = {
      filters: JSON.stringify({ module_id: 'metad3_ctp', 'user_input.service': 'HCTP' }),
      searchQuery: searchQuery !== '' ? searchQuery : undefined,
      sort_order: sortOrder,
      from: 1,
      size: 100,
    };
    dispatch(ctpWorkspaceStatusAction(queryString.stringify(request1)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
  };

  const tagsGroup = (list1) => (
    <div className="tags-group">
      <div className="tags">
        {list1.map((item) => (
          <Tag key={item} className="sample-tags" closable={false}>
            {item}
          </Tag>
        ))}
      </div>
    </div>
  );

  const onViewOutput = (job) => {
    if (job?.status.toLowerCase() === 'completed') {
      navigate(`${terminalsPath.metad3_ctp_output}?jobId=${job.job_id}&service=${job.user_input.service}&nctId=${job.user_input.nct_id}&dataset=${job.user_input.dataset}`);
    } else {
      navigate(terminalsPath.metad3_ctp);
    }
  };

  const onSearchClick = (text) => {
    const request = {
      filters: JSON.stringify({ module_id: 'metad3_ctp' }),
      searchQuery: text,
      from: 1,
      size: 100,
      sort_order: sortOrder,
    };
    dispatch(ctpWorkspaceStatusAction(queryString.stringify(request)));
    setIsSearchEnabled(false);
    setSearchQuery(text);
  };

  const onSortClick = () => {
    const request = {
      filters: JSON.stringify({ module_id: 'metad3_ctp' }),
      searchQuery: searchQuery !== '' ? searchQuery : undefined,
      from: 1,
      size: 100,
      sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
    };
    dispatch(ctpWorkspaceStatusAction(queryString.stringify(request)));
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const jobsList = (data1) => (
    <div className="jobs-list">
      {data1?.map((job, index) => (
        <div className={`job-item${job.job_id === currentJob.job_id ? ' active' : ''}${editing.isEditing && editing.editingId === index ? ' input-edit' : ''}`} key={job.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job.job_name ?? job.module_id}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                  //   onBlur={() => setEditing({ isEditing: false, editingId: '' })}
                />
              </div>
            ) : (
              <div className="job-title">{job.job_name ?? job.module_id}</div>
            )}
            <div className="job-created-at">
              {formatDate(job.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const inputDetails = (job) => (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{job?.job_name || job?.module_id}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
            <div className="study-tag">Optimize Study Protocols</div>
          </div>
          <div className="view-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                onViewOutput(job);
                dispatch(ctpWorkspaceStatusResetAction({}));
                dispatch(generateHCTPCombinationsResetAction({}));
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Output'}`}
            </Button>
          </div>
        </div>
        {job?.description && (
        <div className="description-main">
          <div className="title">Description</div>
          <div className="content">{job?.description}</div>
        </div>
        )}
        <div className="drug-indication-table">
          {/* <HCTPTableView data={dummyData} /> */}
          <div className="drug-indication-pairs-main">
            <div className="title">
              Drug-Indication Pairs
            </div>
            <div className="pairs-list">
              {tagsGroup(job?.user_input.drugs?.map((drug, index) => `${drug}~${job?.user_input.indications[index]}`))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const serviceDropdowns = () => (
    <div className="service-dropdowns">
      <div role="presentation" className={`service-dropdown ${(currentService[2] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([0, 0, 1])}>
        <div className="service-dropdown-title">
          HCTP
        </div>
        { currentService[2] === 1 && (
        <div className="service-dropdown-content">
          {jobsList(serviceJobsList.hctp)}
        </div>
        )}
      </div>
      {/* <div role="presentation" className={`service-dropdown ${(currentService[1] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([0, 1, 0])}>
        <div className="service-dropdown-title">
          HCTP-2
        </div>
        { currentService[1] === 1 && (
        <div className="service-dropdown-content">
          {jobsList(serviceJobsList.hctp)}
        </div>
        )}
      </div> */}
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="meta-ctp" width={550} />
      <Loader loading={ctpWorkspaceStatusResetAction.loading && isLoading}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              {isSearchEnabled ? (
                <div className="search-input">
                  <Input
                    placeholder="Job Name"
                    // value={editing.editingName ?? job.job_name ?? job.module_id}
                    // onChange={(e) => setSearchQuery(e.target.value)}
                    onPressEnter={(e) => onSearchClick(e.target.value)}
                  />
                </div>
              ) : (
                <div className="btns-row">
                  <div className="back-btn">
                    <Button
                      onClick={() => {
                        navigate(terminalsPath.metad3_ctp);
                        dispatch(ctpWorkspaceStatusResetAction({}));
                      }}
                      className="back-btn-icon"
                    >
                      Back
                    </Button>
                  </div>
                  <div className="grouped-btns">
                    <div className="search-btn" style={{ cursor: 'pointer' }}>
                      <div className="search-btn-icon" onClick={() => setIsSearchEnabled(true)} aria-hidden />
                    </div>
                    <div className="sort-btn" style={{ cursor: 'pointer' }}>
                      <div className="sort-btn-icon" onClick={() => onSortClick()} aria-hidden />
                    </div>
                  </div>
                </div>
              )}
              {serviceDropdowns()}
              {/* {jobsList()} */}
            </div>

            {currentJob && <div className="job-details-view">{currentJob?.user_input && inputDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default MyWorkspacePageCTP;
